@import url(https://fonts.googleapis.com/css?family=Montserrat:500);
:root {
    box-sizing: border-box;
}

*,
::before,
::after {
    box-sizing: inherit;
}

html {
    background-color: #6f757e;
    margin: 0;
    padding: 0;
}

.App {
    max-width: 720px;
    margin: 0 auto;
    font-family: 'Montserrat', sans-serif;
}

.Graph-Body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, auto);
    grid-gap: 1em;
}

.Graph-Body > svg {
    background-color: #9db5b2;
    border-radius: 5px;
    width: 100%;
}

.Title {
    color: white;
    background-color: none;
    padding: 10px 20px;
    margin: 0 auto;
    border-radius: 5px;
}

.Circle {
    stroke: white;
    fill: white;
}

.Line {
    stroke: white;
    stroke-width: 0.5
}

