@import url('https://fonts.googleapis.com/css?family=Montserrat:500');

:root {
    box-sizing: border-box;
}

*,
::before,
::after {
    box-sizing: inherit;
}

html {
    background-color: #6f757e;
    margin: 0;
    padding: 0;
}
