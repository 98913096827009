.App {
    max-width: 720px;
    margin: 0 auto;
    font-family: 'Montserrat', sans-serif;
}

.Graph-Body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, auto);
    grid-gap: 1em;
}

.Graph-Body > svg {
    background-color: #9db5b2;
    border-radius: 5px;
    width: 100%;
}

.Title {
    color: white;
    background-color: none;
    padding: 10px 20px;
    margin: 0 auto;
    border-radius: 5px;
}
